import React from 'react';

import Hero from '../components/common/hero';
import Layout from '../components/common/layout';
import ServiceSection from '../components/index/service-section';

export default () => {
  return (
    <>
      <Layout>
        <Hero />
        <div className="content">
          <ServiceSection />
        </div>
      </Layout>
    </>
  );
};
