import React from 'react';
import styled from '@emotion/styled';

const StyledServiceBox = styled.div`
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  padding: 50px 15px;
  margin-bottom: 30px;
  //max-width: 40%;
  flex: 0 0 80%;
  //padding: 0.5rem calc((100vw - 1140px - 0.5rem) / 2);
  //min-width: 500px;
  //max-width: 475px;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    ...;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    ...;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    max-width: 40%;
    flex: 0 0 40%;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    max-width: 20%;
    flex: 0 0 20%;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const TextWrapper = styled.div`
  text-align: center;
`;

const ServiceBox = ({ svg, text }) => {
  return (
    <StyledServiceBox>
      <IconWrapper>
        {/*<Bolt color={"#4caf50"} height="50px" width="50px"/>*/}
        {svg}
      </IconWrapper>
      <TextWrapper>{text}</TextWrapper>
    </StyledServiceBox>
  );
};

export default ServiceBox;
