import React from 'react';
import Bolt from '../../assets/bolt.svg';
import FireExtinguiser from '../../assets/fire-extinguisher.svg';
import ItedItur from '../../assets/itur_ited.svg';
import Fttx from '../../assets/fttx.svg';

export const SERVICE_BOXES = [
  {
    text: 'Projetos de Redes de Fibra FTTX para operadores',
    svg: <Fttx height="50px" width="50px" />,
  },
  {
    text: 'Projetos de Segurança Contra Riscos de Incêndio',
    svg: <FireExtinguiser color={'#00bcd4'} height="50px" width="50px" />,
  },
  {
    text: 'Projetos Elétricos',
    svg: <Bolt color={'#4caf50'} height="50px" width="50px" />,
  },
  {
    text: 'Projetos ITED e ITUR',
    svg: <ItedItur height="50px"  />,
  },
];
