import React from 'react';
import styled from '@emotion/styled';

import ServiceBox from './service-box';

import { SERVICE_BOXES } from '../../data/constants';

const ServicesBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TextWrapper = styled.div`
  text-align: center;
  max-width: 570px;
  h1 {
    color: #0088cc;
    margin-bottom: 15px;
  }
`;

export default () => {
  return (
    <>
      <TextWrapper>
        <h1>Serviços</h1>
        <p>
          O focos da GHCP está centrado na satisfação dos clientes e no
          acompanhamento permanente.
        </p>
      </TextWrapper>
      <div className="content">
        <ServicesBoxWrapper>
          {SERVICE_BOXES.map(({ text, svg }) => (
            <ServiceBox {...{ text, svg }} />
          ))}
        </ServicesBoxWrapper>
      </div>
    </>
  );
};
